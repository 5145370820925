<template>
  <v-card elevation="0">
    <v-data-table
      dense
      :loading="loading"
      :loading-text="$t('loading')"
      :headers="headers"
      :items="items"
      :footer-props="{
        'items-per-page-options': [20, 40, 60, 80, 100],
        'items-per-page-text': $t('table.per-page')
      }"
      :no-data-text="$t('table.no-data')"
      :items-per-page="20"
      :search="search"
      :options.sync="options"
      :server-items-length="total"
      @click:row="clickRow"
    >
      <template #top>
        <v-toolbar flat>
          <v-col>
            <v-row align-self="end">
              <v-col class="text-right">
                <v-btn
                  v-if="hasPermission('api.add_material')"
                  color="primary"
                  dark
                  class="mb-2 mr-2"
                  @click="onNewItem"
                >
                  {{ $t('new') }}
                  <v-icon right dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="hasPermission('api.add_material')"
                  color="primary"
                  dark
                  class="mb-2 mr-2"
                  @click="onImport"
                >
                  {{ $t('materials.import') }}
                  <v-icon right dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-toolbar>
      </template>

      <template #[`item.created_at`]="{ item }">
        <span>{{ createdAt(item) }}</span>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-icon
          v-if="hasPermission('api.change_material')"
          small
          class="mr-2"
          @click.stop="onEditItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="item.total_parts === 0 && hasPermission('api.delete_material')"
          small
          @click.stop="onDeleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import MaterialService from '@/services/MaterialService.js';
import materialsMixin from './materialsMixin';

export default {
  components: {},
  mixins: [materialsMixin],
  props: {
    search: {
      type: String,
      required: false,
      default: ''
    },
    epoch: {
      type: Number,
      required: true
    }
  },
  data: function() {
    return {
      searchTimeout: null,
      loading: false,

      items: [],
      total: 0,
      selected: [null, null],

      editedIndex: -1,
      editedItem: {},
      editStatusInline: null,
      defaultItem: {},

      options: {}
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('materials.name'),
          value: 'name',
          sortable: true
        },
        {
          text: this.$t('materials.type'),
          value: 'type',
          sortable: true
        },
        {
          text: this.$t('materials.total-parts'),
          value: 'total_parts',
          sortable: true,
          align: 'right'
        },
        {
          text: this.$t('materials.total-quantity'),
          value: 'total_quantity',
          sortable: true,
          align: 'right'
        },
        {
          text: this.$t('actions'),
          value: 'actions',
          sortable: false
        }
      ];
    }
  },

  watch: {
    options: {
      async handler() {
        console.log('#options:', this.options);
        await this.loadItems();
      },
      deep: true
    },
    epoch() {
      this.loadItems();
    },
    search: {
      handler() {
        console.log('#search');
        this.options.page = 1;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.loadItems();
        }, 500);
      }
    }
  },

  mounted() {
    this.$root.$on('add:material', this.onItemAdded);
    this.$root.$on('add:material:part', this.onPartUpdated);
    this.$root.$on('update:material:part', this.onPartUpdated);
  },
  beforeDestroy() {
    this.$root.$off('add:material', this.onItemAdded);
    this.$root.$off('add:material:part', this.onPartUpdated);
    this.$root.$off('update:material:part', this.onPartUpdated);
  },
  methods: {
    async loadItems() {
      this.loading = true;

      console.log('loading.options=', this.options);
      try {
        [[this.items, this.total]] = await Promise.all([
          MaterialService.GetMaterials(this.options, this.search)
        ]);

        // console.log('material types: ', this.material_types);
        console.log('materials: ', this.items);
      } catch (err) {
        this.showError(this, err);
      } finally {
        this.loading = false;
      }
    },

    onEditItem(item) {
      console.log('edit material:', item);
      this.$emit('edit:material', item);
    },

    onItemAdded(item) {
      console.log('onItemAdded:', item);
      this.items.push(item);
    },

    async onPartUpdated() {
      console.log('onPartUpdated');
      await this.loadItems();
    },

    async onDeleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      const res = await this.$swal({
        html: this.$t('materials.delete-confirmation'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      });

      if (res.isDismissed) {
        return;
      }

      try {
        let result = await MaterialService.DeleteMaterial(this.editedItem);
        console.log('result: ', result);
        this.items.splice(this.editedIndex, 1);
      } catch (err) {
        this.showError(this, err);
      }
    },

    onNewItem() {
      this.$emit('new:material');
    },

    createdAt(item) {
      return item.created_at.toLocaleString();
    },

    clickRow(item) {
      this.enterMaterial(item);
    },

    enterMaterial(item) {
      this.$emit('enter:material', item);
    },

    onImport() {
      this.$emit('import:materials');
    }
  }
};
</script>

<style lang="sass"></style>

<template>
  <v-card class="" dense elevation="0">
    <v-card-title>
      <v-btn icon @click="$emit('list:materials')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="headline">{{ title }}</span>
    </v-card-title>

    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|min:1"
                      :name="$t('materials.name')"
                    >
                      <v-text-field
                        ref="name"
                        v-model="item.name"
                        :label="$t('materials.name')"
                        dense
                        :error-messages="errors"
                        @keydown.esc="onCancel"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <validation-provider
                v-slot="{ errors }"
                rules="required|min:1"
                :name="$t('materials.type')"
              >
                <v-select
                  v-model="item.type"
                  :items="types"
                  :menu-props="{ maxHeight: '400' }"
                  :hint="$t('select-hint')"
                  :label="$t('materials.type')"
                  persistent-hint
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="onCancel()">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          :disabled="!isNew && invalid"
          text
          @click="onSave()"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import MaterialService from '@/services/MaterialService.js';
import Mixin from './materialsMixin';

export default {
  mixins: [Mixin],
  props: {
    material: {
      type: Object,
      default: null
    }
  },
  emits: ['update:material'],
  data() {
    return {
      item: { ...this.material },
      material_types: []
    };
  },
  computed: {
    title() {
      if (this.isNew) {
        return this.$t('materials.new');
      }
      return this.$t('materials.edit', { material: this.item.name });
    },
    isNew() {
      return !(this.item.id > 0);
    },
    types() {
      return this.material_types.map(t => {
        return {
          text: t.name,
          material: t.name
        };
      });
    }
  },

  watch: {
    material: {
      handler(val) {
        this.item = { ...val };
      },
      deep: true
    }
  },
  async activated() {
    console.log('materials form activated; item:', this.item);
    await this.init();
    this.focusInput();
  },
  methods: {
    focusInput() {
      console.log('focusInput');
      this.$nextTick(() => {
        this.$refs.name.focus();
      });
    },

    async init() {
      this.material_types = await MaterialService.GetMaterialTypes({}, '');
      console.log('material_types: ', this.material_types);
    },

    async onCancel() {
      this.initialized = false;
      this.$refs.observer.reset();
      this.$emit('list:materials');
    },

    async onSave() {
      const result = await this.$refs.observer.validate();
      if (!result) return;

      console.log('material save: ', this.item);
      try {
        if (this.isNew) {
          let result = await MaterialService.PostMaterial({
            name: this.item.name,
            type: this.item.type
          });
          console.log('result: ', result);
          let m = result.data;
          this.item = Object.assign({}, result.data);
          this.item.total_parts = 0;
          this.item.total_quantity = 0;
          console.log('added material: ', m);
          // t.when_created = this.dateFromISO8601(t.when_created);
          // t.when_ordered = this.dateFromISO8601(t.when_ordered);
          // t.when_delivered = this.dateFromISO8601(t.when_delivered);
          // this.$root.$emit('material_added', t); // use central event hub to notify siblign container
          this.$emit('add:material', this.item);
          this.$root.$emit('add:material', this.item);
          this.$emit('list:materials');
        } else {
          let result = await MaterialService.PatchMaterial(this.item);
          console.log('result: ', result);
          Object.assign(this.item, result.data);
          // console.log('this.item: ', this.item);
          //   this.item.when_created = this.dateFromISO8601(this.item.when_created);
          //   this.item.when_ordered = this.dateFromISO8601(this.item.when_ordered);
          //   this.item.when_delivered = this.dateFromISO8601(
          //     this.item.when_delivered
          //   );

          // this.item.name = this.item.name + '@';
          // this.item.type = this.item.type === 'glue' ? 'cardboard' : 'glue';

          this.$emit('update:material', this.item);
          // this.$root.$emit('input', this.item);
          // this.$root.$emit('input', this.item); // use central event hub to notify siblign container
          // }
          // this.initialized = false;
          this.$emit('list:materials');
        }
      } catch (err) {
        this.showError(this, err);
      }
    }
  }
};
</script>

<style></style>

export default {
  methods: {
    // transform(item) {
    //   return {
    //     ...item,
    //     when_created: this.dateFromISO8601(item.when_created),
    //     quantity: item.pcs * item.boxes
    //   };
    // }
  }
};

<template>
  <v-card elevation="0" class="mx-2">
    <v-card-title>
      <v-text-field
        v-if="searchVisible"
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search')"
        single-line
        hide-details
        clearable
      />
    </v-card-title>
    <!-- <keep-alive exclude="PartList"> -->
    <keep-alive>
      <component
        :is="current"
        v-bind="params"
        :search="search"
        @new:material="onNewMaterial"
        @edit:material="onEditMaterial"
        @list:materials="onBackToMaterialList"
        @update:material="onMaterialUpdated"
        @enter:material="onEnterMaterial"
        @import:materials="onImportMaterials"
        @import:done="onImportDone"
        @add:material="onMaterialAdded"
        @new:material:part="onNewPart"
        @edit:material:part="onEditPart"
        @list:materials:parts="onBackToMaterialList"
        @list:materials:part="onBackToPartList"
        @update:material:part="onMaterialPartUpdated"
      />
    </keep-alive>
  </v-card>
</template>

<script>
import materialList from '@/views/dashboard/components/materials/materialList';
import materialForm from '@/views/dashboard/components/materials/materialForm';
import partList from '@/views/dashboard/components/materials/partList';
import partForm from '@/views/dashboard/components/materials/partForm';
import importMaterials from '@/views/dashboard/components/materials/importMaterials';

export default {
  components: {
    materialList,
    materialForm,
    partList,
    importMaterials
  },
  data: function() {
    return {
      current: materialList,
      item: null,
      epoch: 0,
      params: {
        epoch: 0
      },
      search: '',
      material: null,
      filters: []
    };
  },
  computed: {
    searchVisible() {
      return this.current === materialList || this.current === partList;
    }
  },
  watch: {},
  methods: {
    onNewMaterial() {
      this.current = materialForm;
      this.params = {
        value: {
          id: ''
          // created_at: new Date(),
          // updated_at: new Date()
        }
      };
    },

    onNewPart(material) {
      console.log('onNewPart; material=', material);
      this.current = partForm;
      this.params = {
        part: {
          id: '',
          material: material.id
        }
      };
    },

    onEditMaterial(item) {
      console.log('onEditMaterial:', item);
      this.current = materialForm;
      this.item = item;
      this.params = {
        material: item,
        search: this.search
      };
    },

    onEditPart(item) {
      console.log('onEditPart:', item);
      this.current = partForm;
      this.item = item;
      this.params = {
        part: item,
        search: this.params.search
      };
    },

    onMaterialAdded(item) {
      console.log('onMaterialAdded:', item);
      // Object.assign(this.item, item);
    },

    onMaterialUpdated(item) {
      console.log('onMaterialUpdated:', item);
      Object.assign(this.item, item);
    },

    onMaterialPartUpdated(item) {
      console.log('onMaterialPartUpdated:', item);
      Object.assign(this.item, item);
    },

    onEnterMaterial(item) {
      console.log('onEnterMaterial:', item);
      this.current = partList;
      this.material = item;
      this.filters = [
        {
          field: 'material__name',
          label: this.$t('materials.name'),
          operation: '=',
          value: item.name
        }
      ];
      this.params = {
        search: this.search,
        material: this.material,
        filters: this.filters
      };
    },
    onImportDone() {
      this.epoch++;
      this.params.epoch = this.epoch;
      this.current = materialList;
    },
    onBackToMaterialList() {
      this.current = materialList;
      this.params.epoch = this.epoch;
    },
    onBackToPartList() {
      console.log('onBackToPartList');
      this.current = partList;
      this.params = {
        search: this.search,
        material: this.material,
        filters: this.filters
      };
    },
    onImportMaterials() {
      console.log('onImportMaterials');
      this.current = importMaterials;
    }
  }
};
</script>

<style></style>

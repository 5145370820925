<template>
  <v-card elevation="0">
    <upload
      :title="$t('materials.xlsx-import')"
      mime="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      :statuses="statuses"
      single
      @upload="onImport"
      @close="onUploadClosed"
    />
    <v-data-table
      v-if="imported"
      dense
      :loading="loading"
      :loading-text="$t('loading')"
      :headers="headers"
      :items="items"
      :footer-props="{
        'items-per-page-options': [20, 40, 60, 80, 100],
        'items-per-page-text': $t('table.per-page')
      }"
      :no-data-text="$t('table.no-data')"
      :items-per-page="20"
    >
      <template #[`item.msg`]="{ item }">
        <v-icon v-if="item.imported" color="green">mdi-check</v-icon>
        <v-icon v-else color="red">mdi-alert-circle</v-icon>
        {{ item.msg }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import MaterialService from '@/services/MaterialService.js';

export default {
  components: {
    upload: () => import('@/views/dashboard/components/files/fileToUpload.vue')
  },
  data: function() {
    return {
      items: [],
      statuses: [],
      loading: false,
      imported: false
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('materials.part-id'),
          value: 'part_id',
          sortable: true,
          width: '20%'
        },
        {
          text: this.$t('materials.name'),
          value: 'material',
          sortable: true
        },
        {
          text: this.$t('materials.quantity'),
          value: 'quantity',
          sortable: true
        },
        {
          text: this.$t('materials.delivery-date'),
          value: 'delivery_date',
          sortable: true
        },
        {
          text: this.$t('status'),
          value: 'msg',
          sortable: true
        }
      ];
    }
  },

  activated() {
    this.items = [];
    this.statuses = [];
    this.imported = false;
  },
  methods: {
    async onImport(files) {
      console.log('importMaterials.onImport:', files);
      if (files.length === 0) return;

      try {
        this.loading = true;
        this.items = await MaterialService.ImportMaterials(files[0].file);
        console.log('ImportMaterials.rsp:', this.items);
        this.imported = true;
      } catch (err) {
        this.showError(this, err);
      } finally {
        this.loading = false;
      }
    },
    async onUploadClosed() {
      this.$emit('import:done');
    }
  }
};
</script>

<style></style>
